<template>
    <div>
        <div class="modal-dialog block">
            <div class="modal-content pb-20">
                <div class="modal-header modal-staff__header staff-filter-header">
                    <h3 class="block-title">Выбор отдела</h3>
                    <div class="block-options">
                        <button type="button" class="btn-block-option" @click="$modal.hide('select-struct-modal')">×</button>
                    </div>
                </div>
                <div class="block-content content staff-filter-content">
                    <label v-for="struct in structList" class="staff-filter-radio">
                        <input type="radio" name="filter_user" :checked="struct.id === selectedStruct.id" v-on:change="selectStruct(struct.id, struct.name)">
                        <span class="staff-filter-radio__icon"></span>
                        {{ struct.name }}
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SelectStructModal",
        /*data() {
            return {
                selectedStruct: this.selectedStruct
            }
        },*/
        props: {
            structList: {
                type: Array
            },
            selectStruct: {
                type: Function
            },
            selectedStruct: {
                type: Object
            }
        }
    }
</script>

<style scoped>

</style>
